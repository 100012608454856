module.exports = [
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/student_registration.svg`,
    title: "CET Registration",
    linkText: "Register Now",
    openModal: true,
    moreLinks: [
    {
      title: "Technical Education",
      types: [
        {
          type: "UG",
          linkUrls: [
            {
              title: "MHT-CET 2025",
              linkUrl: "https://mhtcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mht_cet",
            },
            {
              title: "MAH-B.HMCT/ M.HMCT (Integrated) CET-2025",
              linkUrl: "https://bhmctcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mah_bhmct_cet",
            },
            {
              title: "MAH-B.Design-CET-2025",
              linkUrl: "https://bdesign2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mah_bdesign_cet",
            },
            {
              title: "MAH - B.BCA/BBA/BMS/BBM CET 2025",
              linkUrl: "https://bbabcacet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mah_bbabca_cet",
            },
            /* {
              title: "MAH-M.Planning Integrated CET-2025",
              linkUrl: "",
              linkText: "",
              alternativeText: "Registration Will Start Soon",
              enum: "mah_mplanning_cet",
            },
            {
              title: "MAH-M.HMCT Integrated CET-2025",
              linkUrl: "",
              linkText: "",
              alternativeText: "Registration Will Start Soon",
              enum: "mah_mhmctintegrated_cet",
            },
            {
              title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2025 (Additional Attempt)",
              linkUrl: "",
              linkText: "",
              alternativeText: "Registration Will Start Soon",
              enum: "mah_bbabca_reattempt_cet",
            }, */
          ],
        },
        {
          type: "PG",
          linkUrls: [
            {
              title: "MAH-MBA/MMS-CET 2025",
              linkUrl: "https://mbacet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mah_mba_mms_cet",
            },
            /* {
              title: "MAH-M.Arch-CET 2025",
              linkUrl: "",
              linkText: "",
              alternativeText: "Registration Will Start Soon",
              enum: "mah_march_cet",
            }, */
            {
              title: "MAH-M.HMCT-CET 2025",
              linkUrl: "https://mhmctcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mah_mhmct_cet",
            },
            {
              title: "MAH-MCA-CET 2025",
              linkUrl: "https://mcacet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
              linkText: "Register Now",
              alternativeText: "",
              enum: "mah_mca_cet",
            },
          ],
        },
      ],
    },
  {
    title: "Agriculture Education",
    types: [
      {
        type: "",
        linkUrls: [
          {
            title: "MHT-CET-2025",
            linkUrl: "https://mhtcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mht_cet",
          },
        ],
      },
    ],
  },
  {
    title: "Higher Education",
    types: [
      {
        type: "UG",
        linkUrls: [
          {
            title: "MAH-LLB (5-Years) CET-2025",
            linkUrl: "https://llb5cet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_llb_5years_cet",
          },
          {
            title: "MAH-B.Ed (General & Special) & B.Ed ELCT-CET-2025",
            linkUrl: "https://bedcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_bed_general_bed_elct_cet",
          },
          {
            title: "MAH-B.P.Ed.-CET-2025",
            linkUrl: "https://bpedcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_bped_cet",
          },
          {
            title: "MAH-L.L.B.3 Yrs. CET-2025",
            linkUrl: "https://llb3cet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_llb_3years_cet",
          },
          {
            title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2025",
            linkUrl: "https://babscbed2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_ba_bsc_bed_4years",
          },
        ],
      },
      {
        type: "PG",
        linkUrls: [
          {
            title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2025",
            linkUrl: "https://bedmedcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_bed_med_3years_cet",
          },
          {
            title: "M.Ed.- CET 2025",
            linkUrl: "https://medcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_med_cet",
          },
          {
            title: "M.P.Ed. - CET 2025",
            linkUrl: "https://mpedcet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_mped_cet",
          },
        ],
      },
    ],
  },
  {
    title: "Medical Education",
    types: [
      {
        type: "UG",
        linkUrls: [
          {
            title: "MH-Nursing CET 2025 (For B.Sc Nursing, ANM & GNM courses)",
            linkUrl: "",
            linkText: "",
            alternativeText: "Registration Will Start Soon",
            enum: "bsc_nursing_cet",
          },
          {
            title: "MH-DPN/PHN-CET-2025",
            linkUrl: "",
            linkText: "",
            alternativeText: "Registration Will Start Soon",
            enum: "mah_dpnphn_cet",
          },
        ],
      },
      {
        type: "PG",
        linkUrls: [
          {
            title: "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2025",
            linkUrl: "",
            linkText: "",
            alternativeText: "Registration Will Start Soon",
            enum: "mah_pgp_pgo_msc",
          },
        ],
      },
    ],
  },
  {
    title: "Fine Art",
    types: [
      {
        type: "",
        linkUrls: [
          {
            title: "MAH-AAC CET 2025",
            linkUrl: "https://mahaaccet2025.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            linkText: "Register Now",
            alternativeText: "",
            enum: "mah_aac_cet",
          },
        ],
      },
    ],
  },
],
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/edit_profile.svg`,
    title: "Profile",
    linkText: "Edit Profile",
    editModal: true,
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/support-ticket.svg`,
    title: "Help Desk",
    linkText: "Create or Track Issues",
    linkUrl: "https://helpdesk.maharashtracet.org/login.php?do=ext&bk=oauth2.user.p1i2",
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/hall_ticket.svg`,
    title: "Hall Ticket or Admit Card",
    linkText: "Get Hall Ticket",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MHT-CET (PCB) 2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCM) 2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT/ M.HMCT (Integrated) CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bhmct_cet",
              },
               {
                 title: "MAH-B.Design-CET-2025",
                 linkUrl: "",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_bdesign_cet",
               },
               {
                title: "MAH-M.HMCT Integrated CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mhmctintegrated_cet",
              },
               {
                title: "MAH-M.Planning Integrated CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2025 (Additional Attempt)",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                 title: "MAH-MBA/MMS-CET 2025",
                 linkUrl: "",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_mba_mms_cet",
              },
              {
                title: "MAH-M.Arch-CET 2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mhmct_cet",
              },
              {
                 title: "MAH-MCA-CET 2025",
                 linkUrl: "",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_mca_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
              // {
              //   title: "MHT-CET-2025",
              //   linkUrl: "",
              //   linkText: "Get Hall Ticket",
              //   alternativeText: "",
              //   enum: "mht_cet",
              // },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MAH-LLB (5-Years) CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_llb_5years_cet",
               }, 
               {
                 title: "MAH-B.Ed (General & Special) & B.Ed ELCT-CET-2025",
                 linkUrl: "",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_bed_general_bed_elct_cet",
               },
               {
                 title: "MAH-B.P.Ed.-CET-2025",
                 linkUrl: "",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_bped_cet",
               },
               {
                 title: "MAH-L.L.B.3 Yrs. CET-2025",
                 linkUrl: "",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_llb_3years_cet",
               },
              {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2025",
                linkUrl:"",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_ba_bsc_bed_4years",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "MAH-M.Ed CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_med_cet",
              },
              {
                title: "MAH-M.P.Ed. CET",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MH-Nursing CET 2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "bsc_nursing_cet",
              },
              {
                title: "MH-DPN/PHN-CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_dpnphn_cet",
              },
              {
                title: "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2025",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              // {
              //   title:
              //     "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2025",
              //   linkUrl: "",
              //   linkText: "Get Hall Ticket",
              //   alternativeText: "",
              //   enum: "mah_pgp_pgo_msc",
              // },
            ],
          },
        ],
      },
      {
        title: "Fine Art",
        types: [
          {
            type: "",
            linkUrls: [
              {
                title: "MAH-AAC CET",
                linkUrl: "",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/objection_tracking.svg`,
    title: "Objection Tracker",
    linkText: "Track Here",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              /*{
                title: "MHT-CET (PCB) 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCM) 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              },*/
              {
                title: "MHT-CET (PCM) 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCB) 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT/ M.HMCT (Integrated) CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_bhmct_cet",
              },
              {
                title: "MAH-B.Design-CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_bdesign_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH-M.Planning Integrated CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2025 (Additional Attempt)",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
               {
                title: "MAH-MBA/MMS-CET 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_mba_mms_cet",
              }, 
              {
                title: "MAH-M.Arch-CET 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_mhmct_cet",
              },
               {
                title: "MAH-MCA-CET 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_mca_cet",
              },
              {
                title: "MAH-M.HMCT Integrated CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_mhmctintegrated_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MAH-LLB (5-Years) CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_llb_5years_cet",
              },
              {
                title: "MAH-B.Ed (General & Special)",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.Ed ELCT-CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_bed_general_bed_elct_cet",
              }, 
              {
                title: "MAH-B.P.Ed.-CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_bped_cet",
              },
               {
                title: "MAH-L.L.B.3 Yrs. CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_llb_3years_cet",
              },
             {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                alternativeText: "",
                enum: "mah_ba_bsc_bed_4years",
              }, 
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "MAH-M.Ed CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_med_cet",
              },
              {
                title: "MAH-M.P.Ed. CET",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MH-Nursing CET 2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "bsc_nursing_cet",
              },
              {
                title: "MH-DPN/PHN-CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_dpnphn_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
        ],
      },
      {
        title: "Fine Arts",
        types: [
          {
            type: "",
            linkUrls: [
              /* {
                title: "MAH-AAC CET",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_aac_cet",
              }, */
              {
                title: "MAH-AAC CET",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
              /* {
                title: "MHT-CET-2025",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              }, */
            ],
          },
        ],
      },
    ],
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/score_card.svg`,
    title: "Score Card",
    linkText: "Get Score Card",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
             /* {
                title: "MHT-CET 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mht_cet",
              }, */
              {
                title: "MHT-CET (PCB) 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCM) 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT/ M.HMCT (Integrated) CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bhmct_cet",
              },
              {
                title: "MAH-B.Design-CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bdesign_cet",
              },
              {
                title: "MAH-M.HMCT Integrated CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_mhmctintegrated_cet",
              },
              {
                title: "MAH-M.Planning Integrated CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2025 (Additional Attempt)",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-MBA/MMS-CET 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_mba_mms_cet",
              }, 
              {
                title: "MAH-M.Arch-CET 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_mhmct_cet",
              },
              {
                title: "MAH-MCA-CET 2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_mca_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
             {
                title: "MAH-LLB (5-Years) CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_llb_5years_cet",
              },
              {
                title: "MAH-B.Ed. (General)",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.Ed & B.Ed ELCT",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.P.Ed.-CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bped_cet",
              },
              {
                title: "MAH-L.L.B.3 Yrs. CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_llb_3years_cet",
              },
              {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_ba_bsc_bed_4years",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "MAH-M.Ed CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_med_cet",
              },
              {
                title: "MAH-M.P.Ed. CET",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
             {
                title: "MH-Nursing CET 2025 (For B.Sc Nursing, ANM & GNM courses)",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "bsc_nursing_cet",
              },
              {
                title: "MH-DPN/PHN-CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_dpnphn_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
             {
                title: "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
        ],
      },
      {
        title: "Fine Arts",
        types: [
          {
            type: "",
            linkUrls: [
             {
                title: "MAH-AAC CET",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
             /* {
                title: "MHT-CET-2025",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mht_cet",
              }, */
            ],
          },
        ],
      },
    ],
  }, 
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/admin_card.svg`,
    title: "ARA",
    linkText: "Get Details",
    linkUrl: "",
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/notification.svg`,
    title: "Notifications",
    linkText: "Get Details",
    linkUrl: "https://cetcell.mahacet.org/notices/",
  },
];
