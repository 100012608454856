import { Container, Button } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';

const handleReload = () => {
  window.location.reload();
}

export default function Error() {
  return (
    <>
      <section className="py-5 py-md-6 py-lg-8 py-xl-10">
        <Container className='text-center d-flex flex-column align-items-center gap-4 gap-md-5'>
          <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/error.svg`} className='cet_error_img' />
          <h4 className='fw-light text-primary m-0'>Something went wrong, please try login again</h4>
          <Button variant="primary" type="submit" onClick={() => handleReload()}>Try again</Button>
        </Container>
      </section>
    </>
  );
}
